import React, { Fragment, useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faInfoCircle, faArrowRight, faArrowDown, faArrowUp, faCog, faThumbtack, faCheckSquare, faTrash, faEdit, faSave, faUndoAlt, faDownload, faUpload, faFileCsv } from '@fortawesome/free-solid-svg-icons';
import Badge from 'react-bootstrap/Badge';
import NewEntryModal from '../components/NewEntryModal';
import UploadCSVModal from '../components/UploadCSVModal';
import UpdateEntryModal from '../components/UpdateEntryModal';
import { CSVDownloader, CSVReader } from 'react-papaparse';
import DropdownButton from 'react-bootstrap/DropdownButton';
import DropdownItem from 'react-bootstrap/DropdownItem';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import ReactTooltip from 'react-tooltip';
import { unwatchFile } from "fs";



const StudentLog = (props) => {
    console.log("Student log props: "+ JSON.stringify(props.props.studentInfo.user_id));
    const [studentInfo, setStudentInfo] = useState(props.props.studentInfo);
    const [studentID, setStudentID] = useState(props.props.studentInfo.user_id);
    const [studentName, setStudentName] = useState(props.props.studentInfo.name);
    const [show, setShow] = useState(false);
    const [showCSVUpload, setShowCSVUpload] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);
    const [selectedStudents, setSelectedStudents] = useState([{studentName: props.props.studentInfo.name, studentID: props.props.studentInfo.user_id}]);
    const [logEntries, setLogEntries] = useState([]);
    const [logSearchTerm, setLogSearchTerm] = useState("");
    const [selectedEntry, setSelectedEntry] = useState({});
    const [sortSelection, setSortSelection] = useState("Sort by");
    const [sortDirection, setSortDirection] = useState(true);
    const sortKeys = {"Date": 1, "Author": 2, "Type": 3, "Contact": 4}

    const getCookieValue = () => {
        let cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)user_name\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        return cookieValue;
    }

    const getUserID = () => {
        let cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)user\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        return cookieValue;
    }

    const getCookieRole = () => {
        let cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)role\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        return cookieValue;
    }

    const handleShow = () => {
        setShow(true);
    }

    const handleClose = () => {
        setShow(false);
        getLogEntries(studentID);
    }

    const handleUpdateClose = () =>{
        setSelectedEntry({});
        setShowUpdate(false);
        getLogEntries(studentID);
    }

    const handleCloseCSVModal = () => {
        setShowCSVUpload(false);
        getLogEntries(studentID);

    }

    const handleShowCSVModal = (e) =>{
        console.log("Showing CSV Modal");
        setShowCSVUpload(true);
    }

    const handleUpdateLogEntry = (entry, student) =>{
        console.log("Handle Update log entry");
        console.log(JSON.stringify(entry));
        console.log(JSON.stringify(student));
        setShowUpdate(true);
        setSelectedEntry({entry, student});
    }

    const getFormatted = (entries) =>{
        
        var formatted = [];
        for(var i = 0; i < entries.length; i++)
        {
            var entry = entries[i];
            console.log(entry);
            formatted.push({
                ID: entry.key,
                Type: entry.props.type,
                Entry: entry.props.log_text,
                Submitted: entry.props.submitted,
                Contact: entry.props.contact,
                ContactDate: entry.props.contactdate,
                Author: entry.props.author
            });
        }
        return formatted;
    }
    

    const formatDate = (date, wTime) =>{
        //console.log("Date: " + date);
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
          ];
        
        
        const dateObj = new Date(date);
        var hours = dateObj.getHours();
        var day_night;
        if(hours>=12)
        {
            if(hours != 12)
                hours=hours-12;
          day_night = "p.m.";
        }
        else
            day_night = "a.m.";
        var minutes = dateObj.getMinutes();
        if(minutes < 10)
          minutes = "0" + minutes;
        var time = hours + ":" + minutes;
        
        if(wTime)
            var formatted = months[dateObj.getMonth()] + " " + dateObj.getDate() + ", " + dateObj.getFullYear() + ", " + time + " " + day_night;
        else
            var formatted = months[dateObj.getMonth()] + " " + dateObj.getDate() + ", " + dateObj.getFullYear();
        return formatted;
    }

    const editLogSearchTerm = (e) => {
        setLogSearchTerm(e.target.value);
    }

    const dynamicLogSearch = (e) =>{
        //console.log(logEntries);
        return logEntries.filter(entry => entry.props.log_text.toLowerCase().includes(logSearchTerm.toLocaleLowerCase()) || entry.props.author.toLowerCase().includes(logSearchTerm.toLocaleLowerCase()));
    }

    async function deleteLogEntry(student, entry)
    {
        var data={student, entry};
        try {
            var request = "/api/students/logentry/delete";
            const rawResponse = await fetch(request, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            var jsonData = await rawResponse.json();
            if(jsonData.success)
                getLogEntries(student.studentID);
        }catch(err)
        {
            console.log(err);
        }
    }

    async function getLogEntries(studentID) {
        //console.log("Waiting on entries for: " + JSON.stringify(student));
        //console.log(editingEntry);
        try {
            var request = "/api/students/logentry/view";
            const rawResponse = await fetch(request, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({studentID: studentID})
            });
        //Check for success or error
        var jsonData = await rawResponse.json();
        console.log(jsonData);
        setLogEntries(jsonData.map(entry=>(
            
                    <div className="entry" log_text={entry.entry_text} key={entry.entry_id} type={entry.entry_type} author={entry.name} submitted={entry.submission_date} contact={entry.contact.toString()} contactdate={entry.contact_date} >
                        <div className="head">
                            
                            <div className="subject">{entry.entry_subject}</div>
                            <div className="entry-info-wrap">
                            <div className="badge-wrap">
                            {entry.contact?
                            
                            <div className="contact" data-tip={formatDate(entry.contact_date)}>
                                <ReactTooltip />
                                <Badge variant="dark" >Two-way Contact</Badge> 
                            </div>:
                            <div className="contact">
                            
                            </div>
                            }
                            <div className="type">{entry.entry_type == "Public"?<Badge variant="success">Type: Public</Badge>: <Badge variant="danger">Type: Internal</Badge>}</div>
                            </div>
                            <div className="submitted-at">{formatDate(entry.submission_date, true)}</div>
                            <div className="author">{entry.name}</div>
                            {entry.author_id == getUserID() || getCookieRole().includes('Administrator')?
                            <div className="edit-entry">
                            <FontAwesomeIcon className="edit-button" onClick={(e) => handleUpdateLogEntry(entry, selectedStudents[0])}icon={faEdit}/>
                            <div className="delete-entry" onClick={function(){if(window.confirm('Are you sure you want to delete this log entry?')){deleteLogEntry(selectedStudents[0], entry)}}}>
                                <FontAwesomeIcon className="delete-button" icon={faTrash}/>
                            </div>
                            </div>
                            :""}
                        </div>
                        </div>
                        <div className="body">
                        {entry.entry_text}
                        </div>
                        {
                        entry.last_update !== entry.submission_date&&
                        <div className="foot">
                            <div className="entry-edited-at">
                                Edited: {formatDate(entry.last_update, true)}
                            </div>
                        </div>
                        }
                    </div>   
        )));
        
        }
        catch(err){
            console.log("Error on submit: " + err);
        }
    }

    async function updateLogEntry()
    {
        try {
            var request = "/api/students/logentry/update";
            const rawResponse = await fetch(request, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(editingEntry)
            });
            var jsonData = await rawResponse.json().then(handleLogClose()).then().then(handleLogShow());

            //getLogEntries(selectedStudents[0]);
            //handleLogShow();
            
        }catch(err)
        {
            console.log(err);
        }
    }

    const editLogEntry = (e, student) =>
    {
        //var editingEntryHolder = entry.entry_id;
        editingEntry.text = e.entry_text;
        editingEntry.id = e.entry_id;
        editingEntry.type = e.entry_type;
        editingEntry.contact = e.contact;
        editingEntry.contactDate = e.contact_date;
        getLogEntries(student);

    }

    const updateEntryText = (e) => {
        //setUpdatedEntryText(e.target.value);
        editingEntry.text = e.target.value;
        console.log(editingEntry.text);
        //getLogEntries(student);
    }

    const handleEntryUpdate = (e, student) =>{
        updateLogEntry(student);
    }

    const handleUpdateEntryType = (e) =>{
        editingEntry.type = e.target.value;
        
    }

    const handleUpdateContact = (e) =>{
        editingEntry.contact = !editingEntry.contact;
        e.target.checked = editingEntry.contact;
    }

    const handleContactDateUpdate = (e) =>{
        editingEntry.contactDate = e.target.value;
    }

    const handleSortSelect = (e) =>{
        console.log("Handling sort select");
        console.log(e);
        sortLogsBy(e);
        console.log(sortSelection);
    }

    const sortLogsBy = (type) =>{
        var obj = [...logEntries];
        switch(parseInt(type))
        {
            case 1:
                    if(sortDirection)
                    {  
                        obj.sort(function(a, b){
                            return a.props.submitted > b.props.submitted?1:-1;
                        });
                    } 
                    else
                    {
                        obj.sort(function(a, b){
                            return a.props.submitted > b.props.submitted?-1:1;
                        });
                    }
                setSortSelection("Date");
            break;
            case 2:
                    if(sortDirection)
                    {  
                        obj.sort(function(a, b){
                            return a.props.author > b.props.author?1:-1;
                        });
                    } 
                    else
                    {
                        obj.sort(function(a, b){
                            return a.props.author > b.props.author?-1:1;
                        });
                    }
                setSortSelection("Author");
            break;
            case 3:
                    if(sortDirection)
                    {  
                        obj.sort(function(a, b){
                            return a.props.type > b.props.type?1:-1;
                        });
                    } 
                    else
                    {
                        obj.sort(function(a, b){
                            return a.props.type > b.props.type?-1:1;
                        });
                    }
                setSortSelection("Type");
            break;

            case 4:
                    if(sortDirection)
                    {  
                        obj.sort(function(a, b){
                            return a.props.contact > b.props.contact?1:-1;
                        });
                    } 
                    else
                    {
                        obj.sort(function(a, b){
                            return a.props.contact > b.props.contact?-1:1;
                        });
                    }
                setSortSelection("Contact");
            break;
            default:
                setSortSelection("Sort by");
                obj.sort(function(a, b){
                    return a.props.submitted > b.props.submitted?1:-1;
                });
                setSortDirection(true);
            break;
        }
        setLogEntries(obj);
    }

    const handleSortDirection = (e) =>{
        setSortDirection(!sortDirection);
        sortLogsBy(sortKeys[sortSelection]);
    }

    const isStudent = () =>
    {
        if(getCookieRole().toLowerCase().includes("administrator") || getCookieRole().toLowerCase().includes("instructor"))
            return false;
        return true;
    }
    
    useEffect(() => {
        getLogEntries(studentID);
        setShow();

    }, []);

    return(
        <Fragment>
            {show&&
            <NewEntryModal inputRef={handleClose} props={{show: show, studentInfo: studentInfo, courseID: props.props.courseID}}></NewEntryModal>
            }
            {showCSVUpload&&
            <UploadCSVModal inputRef={handleCloseCSVModal}props={{show: showCSVUpload}}></UploadCSVModal>
            }
            {
            (JSON.stringify(JSON.parse("{}")) !== JSON.stringify(selectedEntry) && showUpdate) &&
            <UpdateEntryModal inputRef={handleUpdateClose} props={{showUpdate, selectedEntry}}></UpdateEntryModal>   
            }
            <div className="student-log">
            <h4>Student Log for {studentName}</h4>
            <div className="search-wrapper">
            <div className="sort-button-wrapper">
            <DropdownButton style={{borderTopRadius: "0px"}}className="sort-dropdown" title={sortSelection} onSelect={handleSortSelect}>
                <DropdownItem eventKey="1">Date</DropdownItem>
                <DropdownItem eventKey="2">Author</DropdownItem>
                {(!isStudent())&&<DropdownItem eventKey="3">Type</DropdownItem>}
                <DropdownItem eventKey="4">Contact</DropdownItem>
                <Dropdown.Divider />
                <DropdownItem eventKey="5">Clear</DropdownItem>
            </DropdownButton>
            <Button className="sort-direct" onClick={handleSortDirection}><FontAwesomeIcon icon={sortDirection?faArrowUp:faArrowDown}></FontAwesomeIcon></Button>
            </div>
            <FontAwesomeIcon className="search-icon" icon={faSearch}/>
            <input className="log-search-bar" type="text" onChange={editLogSearchTerm} value={logSearchTerm.value} placeholder='Search...'/>
            </div>
            {(!isStudent())?
            <div className="log-entry-buttons">
                <button className="log-entry-button" onClick={handleShow}>+ Add Log Entry</button>
                <button className="log-entry-button" onClick={handleShowCSVModal}><FontAwesomeIcon icon={faUpload}/>Upload CSV</button>
                <CSVDownloader className="log-entry-button"
        data={getFormatted(logEntries)}
        type="button"
        filename={selectedStudents.length>0?selectedStudents[0].studentName +"_entries":"BLANK"}
        bom={true}
      ><FontAwesomeIcon icon={faDownload}/>Download CSV</CSVDownloader>
            </div>:<div></div>}
            {dynamicLogSearch()}
            </div>
        </Fragment>
    )
}

export default StudentLog;