
import React, { Fragment, useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus, faArrowRight, faArrowDown, faArrowUp, faCog, faThumbtack, faCheckSquare, faTrash, faEdit, faSave, faUndoAlt, faDownload, faUpload, faFileCsv } from '@fortawesome/free-solid-svg-icons';

const NewEntryModal = (props) =>{
    var studentsRecieved = false;
    //console.log("NewEntryModal props: " + JSON.stringify(props.props));
    var selectedStudent=[];
    if(props.props.studentInfo === "NONE")
    {
        //console.log(props.props.studentInfo);
        selectedStudent=[];
    }
    else
        selectedStudent=[{studentName: props.props.studentInfo.name, studentID: props.props.studentInfo.user_id}];
    const [show, setShow] = useState(props.props.show);
    const [entrySubject, setEntrySubject] = useState("");
    const [entryText, setEntryText] = useState("");
    const [entryType, setEntryType] = useState("internal");
    const [isContact, setIsContact] = useState(false);
    const [isPinned, setIsPinned] = useState(false);
    const [contactDate, setContactDate] = useState(new Date().toISOString().slice(0, 10));
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [wasSubmitted, setWasSubmitted] = useState(false);
    const [submittedAtDate, setSubmittedAtDate] = useState("");
    const [selectedStudents, setSelectedStudents] = useState(selectedStudent);
    const [updatingEntry, setUpdatingEntry] = useState({});
    const [students, setStudents] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [isSelecting, setIsSelecting] = useState(false);
    const [dropdownSearchValue, setDropdownSearchValue] = useState("");
    //console.log("SS Lengthe: " + JSON.stringify(selectedStudents));

    async function getStudents() {
        if(!studentsRecieved)
        {
            console.log("Getting students");
            try {
                //console.log(props.props.match.params.courseID);
                var response_string = "";
                var courseID = props.props.courseID;
                response_string = "/api/students?courseID=" + courseID;
                //console.log(JSON.stringify(props.match.params.courseID));
                const response = await fetch(response_string);
                const jsonData = await response.json();
                //console.log("STUDENTS: " + JSON.stringify(jsonData));
                jsonData.sort((a, b) => (a.name > b.name) ? 1 : -1);
                setStudents(jsonData);
                studentsRecieved=true;
            }
            catch(err)
            {
                console.log("Error getting students in NewEntryModal.");
            }
        }
    }

    const editDropdownSearchTerm = (e) => {
        setDropdownSearchValue(e.target.value);
    }
    const dynamicSearch = () => {
        return students.filter(student => student.name.toLowerCase().includes(dropdownSearchValue.toLowerCase()));
    }

    const handleClose = () => {
        console.log("Handleing close");
        setEntrySubject("");
        setEntryText("");
        setEntryType("internal");
        setIsContact(false);
        setIsPinned(false);
        setContactDate(new Date().toISOString().slice(0, 10));
        setWasSubmitted(false);
        setSubmittedAtDate("");
        props.inputRef(false);
        setShow(false);
        //props.reRenderRef;
    }
    const handleShow = () => {
        setEntryType("internal");
        setShow(true);
    }

    const setContactToggle = (e) => {
        setIsContact(e.target.checked);
        console.log(isContact);
    }

    const handlePinned = (e) => {
        setIsPinned(!isPinned);
    }

    const handleContactDate = (e) => {
        setContactDate(e.target.value);
        console.log("Set contact date to: " + e.target.value);
    }

    const editEntrySubject = (e) =>{
        setEntrySubject(e.target.value);
    }

    const editEntryText = (e) => {
        setEntryText(e.target.value);
    }

    const editEntryType = (e) => {
        console.log("Editing entry type: " + JSON.stringify(e.currentTarget.value));
        setEntryType(e.currentTarget.value);
    }

    function getDate() {
        var date = new Date(); //create date object 
        var min_date = date.toISOString().slice(0, 10);
        //console.log("Returning date: " + min_date);
        //setContactDate(min_date);
        return min_date;
    }

    const handleEntrySubmit = () => {
        
        var data = {
            subject: entrySubject,
            text: entryText,
            type: entryType,
            contact: isContact,
            contactDate: contactDate,
            pinned: isPinned,
            students: selectedStudents
        }

        console.log("Submitting Entry: ");
        console.log(JSON.stringify(data));
        if(data.text != "" && data.subject != "")
        {
            setIsSubmitting(true);
            submitEntry(data);
        }
    }

    function removeStudentDropdown(student, e) {
        var copy = [...selectedStudents];
        for(var i = 0; i < copy.length; i++)
        {
            //console.log("Searching for student: " + JSON.stringify(student));
            if(copy[i].studentID === student.user_id)
            {
                //console.log("Found student at index: " + i);
                var spliced = selectedStudents.splice(i, 1);
                //elem.classList.remove("selected");
                setSelectedStudents([...selectedStudents]);
                //console.log("Removed student: " + JSON.stringify(spliced));
            }
        }
        setIsSelecting(true);
        setShowDropdown(true);
        e.stopPropagation();
        //console.log("I made it far");
        return false;
    }

    async function submitEntry(data) {
        
        try {
            var request = "/api/students/logentry/add";
            const rawResponse = await fetch(request, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(response => response.json())
            .then(data => {
                if (data.success)
                    setWasSubmitted(true);
                    setSubmittedAtDate(data.submittedAt);
            })
        .then(setIsSubmitting(false))
        //Check for success or error

        }
        catch(err){
            console.log("Error on submit: " + err);
            setIsSubmitting(false);
            setWasSubmitted(false);
        }
    }

    const isSelected = (student) =>{
        if(selectedStudents.some(e=>e.studentID == student.user_id)){
            //console.log("Student is selected");
            return true;
        }
    }

    const selectedToTop = () =>{
        var obj = [...students];
        obj.sort((a,b) => isSelected(a) && !isSelected(b)?-1:1);
        setStudents(obj);
    }

    const addToSelected = (student, e, shouldClose) =>{
        var arr = [...selectedStudents];
        arr.push({studentID: student.user_id, studentName: student.name});
        
        
        //selectedToTop();
        setSelectedStudents(arr);
        if(!shouldClose)
        {
            setIsSelecting(true);
            setShowDropdown(true);
            e.stopPropagation();
        }
        else
        {
            setIsSelecting(false);
            setShowDropdown(false);
        }
        
        //console.log("Adding selected");
    }

    const handleDropdownToggle = (shouldClose) =>{
        //console.log("Handling dropdown");
        if(shouldClose)
        {
            setIsSelecting(false);
            setShowDropdown(false);
            setDropdownSearchValue("");
        }
        else
        setIsSelecting(false);
        setShowDropdown(!showDropdown);
        setDropdownSearchValue("");

    }

    useEffect(() => {   
        getStudents();
        //handleShow();
    }, []);

    

    return(
        
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
            
        <Modal.Header closeButton>
            <Modal.Title className="modal-title">New Log Entry </Modal.Title>
            <DropdownButton disabled={wasSubmitted}  show={showDropdown || isSelecting?true:false} onClick={() => {handleDropdownToggle()}} id="dropdown-basic-button" title={selectedStudents.length>0 ? selectedStudents[0].studentName + " + " + (selectedStudents.length - 1) + " others" : "NONE"}>
            <input type="text" placeholder="Search.." value={dropdownSearchValue} onChange={(e) => editDropdownSearchTerm(e)} onClick={(e) => {e.stopPropagation()}}/>
                {
                    dynamicSearch().map(student => (
                        <Dropdown.Item key={student.user_id} name={student.name} onClick={isSelected(student)? function (e){ removeStudentDropdown(student, e, true)}: function (e) {addToSelected(student, e, true)}} className="student-dropdown" style={isSelected(student)&&{backgroundColor: "#007bff", color: "white", borderTopStyle: "solid", borderTopColor: "white"}}>{student.name}
                        {isSelected(student)?
                        <div className="dropdown-selection" onClick={function (e) { removeStudentDropdown(student, e, false) }}><FontAwesomeIcon icon={faMinus}></FontAwesomeIcon></div>
                        :
                        <div className="dropdown-selection" onClick={function (e) { addToSelected(student, e, false)}}><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon></div>
                        }
                        </Dropdown.Item>
                    ))
                }
            </DropdownButton>
        </Modal.Header>
        <Modal.Body >
            
            <div className="log-entry-head-wrap">
                <div className="entry-type">
                <div className="type-selection">
                        <div className="selection"><label>Internal</label><input name="type" type="radio" value="internal" checked={entryType==="internal"?true:false} onChange={editEntryType} disabled={wasSubmitted?true:false}/></div>
                        <div className="selection"><label>Public</label><input name="type" type="radio" value="public" checked={entryType==="public"?true:false} onChange={editEntryType} disabled={wasSubmitted?true:false}/></div>
                    </div>
                
                    
                    <div className="contact-wrapper">
                        <input id="contact-toggle" onChange={setContactToggle} type="checkbox" disabled={wasSubmitted?true:false}></input>
                        <label style={isContact ? { fontWeight: "bold", opacity: "1" } : {}}>Two-way contact</label>
                    </div>
                    <div className={isContact ? "contact-date contact-enabled" : "contact-enabled"}>
                    <input type="date" value={contactDate === "" ? getDate() : contactDate}  onChange={handleContactDate} disabled={wasSubmitted || !isContact?true:false}/>
                </div>

                    <div className="entry-pin-toggle" onClick={!wasSubmitted?handlePinned:undefined} >
                        <div className={isPinned ? "pin-notify pin-enabled" : "pin-notify"} disabled={wasSubmitted?true:false}>pinned</div>
                        <FontAwesomeIcon icon={faThumbtack} />
                    </div>
                </div>
                
            </div>
            <input type="text" onChange={editEntrySubject} maxLength={50} value={entrySubject.value} placeholder="Subject (50 char max)"/>
            <textarea onChange={editEntryText} maxLength={3000} value={entryText.value} placeholder="Type here..." disabled={wasSubmitted ? true:false}>

            </textarea>

        </Modal.Body>
        {wasSubmitted?<center><div style={{fontSize:"12px"}}>Submitted at: {submittedAtDate}</div></center>:""}
        <Modal.Footer>
            {!wasSubmitted ? 
            <Button variant="primary" onClick={handleEntrySubmit} disabled={selectedStudents.length<1?true:false}>
                {!isSubmitting ? "Submit" : <FontAwesomeIcon className="cog fa-spin" icon={faCog}/>}
            </Button> :
            <Button variant="success" disabled={true}>
            Submitted <FontAwesomeIcon className="cog" icon={faCheckSquare}/>
            </Button>
            }
        </Modal.Footer>
    </Modal>
    )
}

export default NewEntryModal;