
import React, { Fragment, useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowDown, faArrowUp, faCog, faThumbtack, faCheckSquare, faTrash, faEdit, faSave, faUndoAlt, faDownload, faUpload, faFileCsv } from '@fortawesome/free-solid-svg-icons';

const UpdateEntryModal = (props) =>{
    console.log("UpdateEntryModal props: " + JSON.stringify(props.props));
    const studentName = props.props.selectedEntry.student.studentName;
    const studentID = props.props.selectedEntry.student.studentID;
    const entry = props.props.selectedEntry.entry;
    const [show, setShow] = useState(props.props.showUpdate);
    const [entryText, setEntryText] = useState(entry.entry_text);
    const [entrySubject, setEntrySubject] = useState(entry.entry_subject);
    const [entryType, setEntryType] = useState(entry.entry_type);
    const [isContact, setIsContact] = useState(entry.contact);
    const [isPinned, setIsPinned] = useState(entry.is_pinned);
    const [contactDate, setContactDate] = useState(new Date(entry.contact_date).toISOString().slice(0, 10));
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [wasSubmitted, setWasSubmitted] = useState(false);
    const [submittedAtDate, setSubmittedAtDate] = useState("");
    const [selectedStudents, setSelectedStudents] = useState([{studentName: studentName, studentID: studentID}]);

    const handleClose = () => {
        console.log("Handleing close");
        setEntryText("");
        setEntryType("internal");
        setIsContact(false);
        setIsPinned(false);
        setContactDate(new Date().toISOString().slice(0, 10));
        setWasSubmitted(false);
        setSubmittedAtDate("");
        props.inputRef(false);
        setShow(false);
        //props.reRenderRef;
    }
    const handleShow = () => {
        setEntryType("internal");
        setShow(true);
    }

    const setContactToggle = (e) => {
        setIsContact(e.target.checked);
        console.log(isContact);
    }

    const handlePinned = (e) => {
        setIsPinned(!isPinned);
    }

    const handleContactDate = (e) => {
        setContactDate(e.target.value);
        console.log("Set contact date to: " + e.target.value);
    }

    const editEntryText = (e) => {
        setEntryText(e.target.value);
    }

    const editEntrySubject = (e) =>{
        setEntrySubject(e.target.value);
    }

    const editEntryType = (e) => {
        console.log("Editing entry type: " + JSON.stringify(e.currentTarget.value));
        setEntryType(e.currentTarget.value);
    }

    function getDate() {
        var date = new Date(); //create date object 
        var min_date = date.toISOString().slice(0, 10);
        //console.log("Returning date: " + min_date);
        //setContactDate(min_date);
        return min_date;
    }

    const handleEntrySubmit = () => {
        
        var data = {
            subject: entrySubject,
            text: entryText,
            type: entryType.toLowerCase(),
            contact: isContact,
            contactDate: contactDate,
            pinned: isPinned,
            students: selectedStudents,
            id: entry.entry_id,
        }

        console.log("Updating Entry: ");
        console.log(JSON.stringify(data));
        if(data.text != "")
        {
            setIsSubmitting(true);
            updateEntry(data);
        }
    }

    function removeStudentDropdown(student, elem) {
        var copy = [...selectedStudents];
        for(var i = 0; i < copy.length; i++)
        {
            //console.log("Searching for student: " + JSON.stringify(student));
            if(selectedStudents[i].studentID === student.studentID)
            {
                //console.log("Found student at index: " + i);
                var spliced = selectedStudents.splice(i, 1);
                //elem.classList.remove("selected");
                setSelectedStudents([...selectedStudents]);
                //console.log("Removed student: " + JSON.stringify(spliced));
                
                return true;
            }
        }
        //console.log("I made it far");
        return false;
    }

    async function updateEntry(data) {
        
        try {
            var request = "/api/students/logentry/update";
            const rawResponse = await fetch(request, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(response => response.json())
            .then(data => {
                if (data.success)
                    setWasSubmitted(true);
                    setSubmittedAtDate(data.submittedAt);
            })
        .then(setIsSubmitting(false))
        //Check for success or error

        }
        catch(err){
            console.log("Error on submit: " + err);
            setIsSubmitting(false);
            setWasSubmitted(false);
        }
    }

    useEffect(() => {
        
        //handleShow();
        

    }, []);

    

    return(
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
            <Modal.Title className="modal-title">Update Log Entry </Modal.Title>
            <DropdownButton disabled={true} id="dropdown-basic-button" title={selectedStudents[0] ? selectedStudents[0].studentName + " + " + (selectedStudents.length - 1) + " others" : "NONE"}>
                {
                    selectedStudents.map(student => (
                        <Dropdown.Item key={student.studentID} className="student-dropdown">{student.studentName}<div className="dropdown-selection" onClick={function () { removeStudentDropdown(student) }}>x</div></Dropdown.Item>
                    ))
                }
            </DropdownButton>
        </Modal.Header>
        <Modal.Body>
            
            <div className="log-entry-head-wrap">
                <div className="entry-type">
                <div className="type-selection">
                        <div className="selection"><label>Internal</label><input name="type" type="radio" value="Internal" checked={entryType.toLowerCase()==="internal"?true:false} onChange={editEntryType} disabled={wasSubmitted?true:false}/></div>
                        <div className="selection"><label>Public</label><input name="type" type="radio" value="Public" checked={entryType.toLowerCase()==="public"?true:false} onChange={editEntryType} disabled={wasSubmitted?true:false}/></div>
                    </div>
                
                    
                    <div className="contact-wrapper">
                        <input id="contact-toggle" onChange={setContactToggle} type="checkbox" checked={isContact} disabled={wasSubmitted?true:false}></input>
                        <label style={isContact ? { fontWeight: "bold", opacity: "1" } : {}}>Two-way contact</label>
                    </div>
                    <div className={isContact ? "contact-date contact-enabled" : "contact-enabled"}>
                    <input type="date" value={contactDate === "" ? getDate() : contactDate}  onChange={handleContactDate} disabled={wasSubmitted || !isContact?true:false}/>
                </div>

                    <div className="entry-pin-toggle" onClick={!wasSubmitted?handlePinned:undefined} >
                        <div className={isPinned ? "pin-notify pin-enabled" : "pin-notify"} disabled={wasSubmitted?true:false}>pinned</div>
                        <FontAwesomeIcon icon={faThumbtack} />
                    </div>
                </div>
                
            </div>
            <input type="text" onChange={editEntrySubject} maxLength={50} value={entrySubject} placeholder="Subject" disabled={wasSubmitted ? true:false}/>
            <textarea onChange={editEntryText} maxLength={3000} value={entryText} placeholder="Type here..." disabled={wasSubmitted ? true:false}>

            </textarea>

        </Modal.Body>
        {wasSubmitted?<center><div style={{fontSize:"12px"}}>Submitted at: {submittedAtDate}</div></center>:""}
        <Modal.Footer>
            {!wasSubmitted ? 
            <Button variant="primary" onClick={handleEntrySubmit} disabled={selectedStudents.length<1 || entryText=="" || entrySubject==""?true:false}>
                {!isSubmitting ? "Submit" : <FontAwesomeIcon className="cog fa-spin" icon={faCog}/>}
            </Button> :
            <Button variant="success" disabled={true}>
            Entry Updated! <FontAwesomeIcon className="cog" icon={faCheckSquare}/>
            </Button>
            }
        </Modal.Footer>
    </Modal>
    )
}

export default UpdateEntryModal;