import React, { Fragment, useState, useEffect } from "react";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { CSVDownloader, CSVReader } from 'react-papaparse';
import FlipMove from 'react-flip-move';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faExclamationTriangle, faArrowDown, faArrowUp, faInfoCircle, faCog, faThumbtack, faCheckSquare, faTrash, faEdit, faSave, faUndoAlt, faDownload, faUpload, faFileCsv } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import { useHistory } from "react-router-dom";
import NewEntryModal from "./NewEntryModal";


const Students = (props) => {
    const history = useHistory();
    const [students, setStudents] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [nameSort, setNameSort] = useState(false);
    const [missingAssignmentSort, setMissingAssignmentSort] = useState(false);
    const [riskSort, setRiskSort] = useState(false);
    const [trendSort, setTrendSort] = useState(false);
    const [courseInfo, setCourseInfo] = useState({});
    //const [courseAttendance, setCourseAttendance] = useState({});
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [show, setShow] = useState(false);
    const [showLog, setShowLog] = useState(false);
    const [entryText, setEntryText] = useState("");
    const [entryType, setEntryType] = useState("internal");
    const [isContact, setIsContact] = useState(false);
    const [isPinned, setIsPinned] = useState(false);
    const [contactDate, setContactDate] = useState(new Date().toISOString().slice(0, 10));
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [wasSubmitted, setWasSubmitted] = useState(false);
    const [submittedAtDate, setSubmittedAtDate] = useState("");
    const [logEntries, setLogEntries] = useState([]);
    const [avgsData, setAvgsData] = useState({views: {avg: "N/A"}, submissions: {avg: "N/A"}});
    const [attendanceData, setAttendanceData] = useState({});
    const [typeSort, setTypeSort] = useState(true);
    const [authSort, setAuthSort] = useState(true);
    const [submissionSort, setSubmissionSort] = useState(true);
    const [logSearchTerm, setLogSearchTerm] = useState("");
    const [showCSVModal, setShowCSVModal] = useState(false);
    const [csvErrors, setCsvErrors] = useState({});
    const [csvFile, setCsvFile] = useState({});
    const [lastUpdate, setLastUpdate] = useState([{last_update: 'none'}]);
    //const [editingEntry, setEditingEntry] = useState();

    var editingEntry = {id: 0, text: "", type: "", contact: false, contactDate: new Date().toISOString().slice(0, 10), pinned: false}
    const newEditEntry = {id: 0, text: "", type: "", contact: false, contactDate: new Date().toISOString().slice(0, 10), pinned: false}
    
    const routeChange = (courseID, studentID) =>{
        if(courseID==1)
        {
	   console.log("Student school wide route change");
           getCourseList(studentID);
           return;
        }
        
        let path = `/StudentView/` + courseID + '/' + studentID; 
        history.push(path);
    }

    async function getCourseList(studentID)
    {
        try {
            var response_string = "";
            response_string = "/api/students/courselist?id=" + parseInt(studentID);
            const response = await fetch(response_string);
            const jsonData = await response.json();
            var courseID = jsonData.course_id;
            let path = `/StudentView/` + courseID + '/' + studentID; 
            history.push(path);
            
        }
        catch (err) {
            console.log(err);
        }
    }


    const handleClose = () => {
        setEntryText("");
        setEntryType("Internal");
        setIsContact(false);
        setIsPinned(false);
        setContactDate(new Date().toISOString().slice(0, 10));
        setShow(false);
        setWasSubmitted(false);
        setSubmittedAtDate("");
    }
    const handleShow = () => {
        setEntryType("internal");
        setShow(true);
    }

    const handleLogClose = () =>{
        console.log("Closing log");
        editingEntry = JSON.parse(JSON.stringify(newEditEntry));
        setShowLog(false);
        
    }

    const handleLogShow = () =>{
        console.log("On show, editingEntry: " + JSON.stringify(editingEntry));
        getLogEntries(selectedStudents[0]);
        getLogEntries(selectedStudents[0]);
        setShowLog(true);
    }

    const getCookieValue = () => {
        let cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)user_name\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        return cookieValue;
    }

    const getCookieRole = () => {
        let cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)role\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        return cookieValue;
    }

    const sortClick = (type) => {
        var obj = [...students];
        if (type === "name") {
            if (nameSort) {
                obj.sort(function(a, b){
                    return a.props.sname.toLowerCase().localeCompare(b.props.sname.toLowerCase());
                });
            }
            else {
                obj.sort(function(a, b){
                    return b.props.sname.toLowerCase().localeCompare(a.props.sname.toLowerCase());
                });
            }
            setNameSort(!nameSort);
        }
        if (type === "missing-assignments") {
            if (missingAssignmentSort) {
                obj.sort(function(a, b){
                    return a.props.missing > b.props.missing?1:-1;
                });
            }
            else {
                obj.sort(function(a, b){
                    return a.props.missing > b.props.missing?-1:1;
                });
            }
            setMissingAssignmentSort(!missingAssignmentSort);
        }
        if (type === "risk") {
            if (riskSort) {
                obj.sort(function(a, b){
                    return a.props.risk > b.props.risk?1:-1;
                });
            }
            else {
                obj.sort(function(a, b){
                    return a.props.risk > b.props.risk?-1:1;
                });
            }
            setRiskSort(!riskSort);
        }
        if (type === "trend") {
            if (trendSort) {
                obj.sort(function(a, b){
                    return a.props.trend > b.props.trend?1:-1;
                });
            }
            else {
                obj.sort(function(a, b){
                    return a.props.trend > b.props.trend?-1:1;
                });
            }
            setTrendSort(!trendSort);
        }
        setStudents(obj);
    }

    async function getCourseInfo() {
        try {
            var response_string = "";
            response_string = "/api/course/info?id=" + parseInt(props.props.match.params.courseID);
            const response = await fetch(response_string);
            const jsonData = await response.json();
            setCourseInfo(jsonData.info);
            console.log(jsonData.info);
        }
        catch (err) {
            console.log(err);
        }
    }

    async function getLastUpdate(){
        try {
            var response_string = "";
            response_string = "/api/course/lastupdate?id=" + parseInt(props.props.match.params.courseID);
            const response = await fetch(response_string);
            const jsonData = await response.json();
            console.log(JSON.stringify(jsonData));
            setLastUpdate(jsonData);
        }
        catch (err) {
            console.log(err);
        }
    }

    // async function getCourseAttendance(props) {
    //     try {
    //         var response_string = "";
    //         response_string = "/course/attendance?id=" + parseInt(props.props.match.params.courseID);
    //         const response = await fetch(response_string);
    //         const jsonData = await response.json();
    //         console.log("Attendance Info: " + JSON.stringify(jsonData));
    //         setCourseAttendance(jsonData.attendance);
    //     }
    //     catch (err) {

    //     }
    // }

    function findAttendance(attendance, student) {
        try {
            var attended = attendance.find(obj => obj.login_id == student);
            return attended.attended;
        }
        catch{
            return 0;
        }

    }

    function findLastAttended(attendance, student) {
        try {
            var attended = attendance.find(obj => obj.login_id == student);
            return attended.last_attended;
        }
        catch{
            return "N/A";
        }
    }

    // function studentSelect(elem) {
    //     var student = elem.target.parentNode.parentNode.parentNode;
    //     var logEntryButton = document.getElementById("log-entry");
    //     if (student.className.includes("student") && !student.className.includes("selected")) {
    //         student.classList.add("selected");
    //         console.log("Add: " + student.getAttribute("sname"));
    //         selectedStudents.push({ studentName: student.getAttribute("sname"), studentID: student.getAttribute("userid") });
    //         setSelectedStudents([...selectedStudents]);
    //     }
    //     else {
    //         student.classList.remove("selected");
    //         console.log("Remove: " + student.getAttribute("sname"));
    //         selectedStudents.pop({ studentName: student.getAttribute("sname"), studentID: student.getAttribute("userid") });
    //         //removeStudentDropdown({ studentName: student.getAttribute("sname"), studentID: student.getAttribute("userid") });
    //     }

    //     if (selectedStudents.length > 0) {
    //         enableButton(logEntryButton);
    //     }
    //     else
    //         disableButton(logEntryButton);

    //     console.log(selectedStudents);
    // }
    function removeStudentDropdown(student, elem) {
        var copy = [...selectedStudents];
        for(var i = 0; i < copy.length; i++)
        {
            //console.log("Searching for student: " + JSON.stringify(student));
            if(selectedStudents[i].studentID === student.studentID)
            {
                //console.log("Found student at index: " + i);
                var spliced = selectedStudents.splice(i, 1);
                //elem.classList.remove("selected");
                setSelectedStudents([...selectedStudents]);
                //console.log("Removed student: " + JSON.stringify(spliced));
                getStudents(props);
                return true;
            }
        }
        //console.log("I made it far");
        return false;
    }

    const studentSelect = (student, elem) =>
    {
        var studentElem = elem.target.parentNode.parentNode.parentNode;
        var selectedStudent = {studentName: student.name, studentID: student.user_id};
        if(!removeStudentDropdown(selectedStudent, studentElem))
        {
            selectedStudents.push(selectedStudent);
            setSelectedStudents([...selectedStudents]);
        }
        else   
            return;
        
        getStudents(props);
        console.log(selectedStudents);
    }

    function isSelected(student)
    {
        for(var i = 0; i < selectedStudents.length; i++)
        {
            if(selectedStudents[i].studentID === student.user_id)
            {
                //console.log("isSelected ran.");
                return true;
            }
        }
        return false
    }

    async function getAvgsData(props)
    {
        const avgsResponse = await fetch("/api/course/averages?id=" + props.props.match.params.courseID);
        const avgsData = await avgsResponse.json();
        console.log(avgsData);
        setAvgsData(avgsData);
    }

    async function getCourseAttendance()
    {
        const attendanceResponse = await fetch("/api/course/attendance?id=" + parseInt(props.props.match.params.courseID));
        const classAttendance = await attendanceResponse.json();
        console.log(classAttendance);
        setAttendanceData(classAttendance);
    }

    
    async function setSchoolStudents()
    {
        const response = await fetch("/api/students/school");
        const jsonData = await response.json();
        jsonData.sort((a, b) => (a.name > b.name) ? 1 : -1);
        console.log(JSON.stringify(jsonData[0].student_participation));

        setCourseInfo({name: "School-wide"});

        setStudents(jsonData.map(student => (
            <div className={isSelected(student) ? "student selected" : "student"} key={student.user_id} userid={student.user_id} sname={student.name} missing={student.missing_assignments} risk={student.student_participation} trend={student.trend}>
                    {jsonData.length < 50?<ReactTooltip />:""}
                    <div className="person">
                        <div className="profile-pic">
                            <img src={student.avatar_url}></img>
                        </div>
                        <div className="name" onClick={() => routeChange(1, student.user_id)}>
                            {student.name}
                        </div>
                    </div>
                    <div className="missing-assignments">
                        {student.missing_assignments}
                    </div>
                    <div className="participation-container">
                        {/* <div className="inspect">
                            <div className="inspect-wrapper">
                                <div className="inspect-elem">
                                    <div className="inspect-elem-title" data-tip="Student's average daily course views">Views</div>
                                    <ProgressBar variant="info" now={parseFloat(student.views_avg).toFixed(2)} max={parseFloat(avgsData.views.avg).toFixed(2)} />
                                </div>
                                <div className="inspect-elem">
                                    <center><div className="inspect-elem-title" data-tip="Student's average daily course submissions">Submissions</div></center>
                                    <ProgressBar variant="info" now={parseFloat(student.submissions_avg).toFixed(2)} max={parseFloat(avgsData.submissions.avg).toFixed(2)} />

                                </div>
                                <div className="inspect-elem">
                                    <center><div className="inspect-elem-title">Attendance</div></center>
                                    <ProgressBar variant="info" label={100} />

                                </div>
                            </div>
                        </div> */}

                        

                        <div className="participation-bar">
                            {student.student_participation >= 0?
                            <div>
                            {100 - (student.student_participation * 100) <= 50 &&
                                <ProgressBar  variant="success" now={100 - (student.student_participation * 100)} />
                            }

                            {100 - (student.student_participation * 100) <= 75 && 100 - (student.student_participation * 100) > 50 &&
                                <ProgressBar  variant="warning" now={100 - (student.student_participation * 100)} />
                            }

                            {100 - (student.student_participation * 100) > 75 &&
                                <ProgressBar  variant="danger" now={100 - (student.student_participation * 100)} />
                            }
                            </div>:<center><FontAwesomeIcon icon={faExclamationTriangle} data-tip={"Not enough data"}></FontAwesomeIcon>  Incomplete data</center>}
                        </div>
                    </div>
                    <div className="trend-container">
                        
                        <div className="trend-arrow">
                            {(student.trend >= -.1 && student.trend <= .1) &&
                                <FontAwesomeIcon icon={faArrowRight} data-tip="Students risk was netural over the last week." />
                            }

                            {student.trend > .1 &&
                                <FontAwesomeIcon icon={faArrowDown} style={{ color: "#04d10e" }} data-tip="Students risk decreased over the last week." />
                            }

                            {student.trend < -.1 &&
                                <FontAwesomeIcon icon={faArrowUp} style={{ color: "#d10415" }} data-tip="Students risk increased over the last week." />
                            }
                        </div>
                    </div>
                </div>)));
    }


    async function getStudents(props, type) {
        try {
            
            if(props.props.match.params.courseID === 'school')
            {
                console.log(props.props.match.params.courseID);
                setSchoolStudents();
                return;
            }
            
            console.log("Getting students start: " + new Date().getMinutes() + "|" + new Date().getSeconds());
            var response_string = "";
            var courseID = props.props.match.params.courseID;
            if (type) {
                response_string = "/api/students?courseID=" + props.props.match.params.courseID + "&sort_type=" + type;
            }
            else
                response_string = "/api/students?courseID=" + props.props.match.params.courseID;
            //console.log(JSON.stringify(props.match.params.courseID));
            const response = await fetch(response_string);
            const jsonData = await response.json();
            jsonData.sort((a, b) => (a.name > b.name) ? 1 : -1);

            console.log(attendanceData);
            const attendanceResponse = await fetch("/api/course/attendance?id=" + parseInt(props.props.match.params.courseID));
            const classAttendance = await attendanceResponse.json();
            console.log(classAttendance);
            setAttendanceData(classAttendance);

            const avgsResponse = await fetch("/api/course/averages?id=" + props.props.match.params.courseID);
            const avgsData = await avgsResponse.json();
            console.log(avgsData);
            setAvgsData(avgsData);

            let info_string = "/api/course/info?id=" + parseInt(props.props.match.params.courseID);
            const info = await fetch(info_string);
            const courseInfo = await info.json();
            

            setStudents(jsonData.map(student => (
                <div className={isSelected(student) ? "student selected" : "student"} key={student.user_id} userid={student.user_id} sname={student.name} missing={student.missing_assignments} risk={student.student_participation} trend={student.trend}>
                    {jsonData.length < 50?<ReactTooltip />:""}
                    <div className="person">
                        <div className="profile-pic">
                            <img src={student.avatar_url}></img>
                        </div>
                        <div className="name" onClick={() => routeChange(courseID, student.user_id)}>
                            {student.name}
                        </div>
                    </div>
                    <div className="missing-assignments">
                        {student.missing_assignments}
                    </div>
                    <div className="participation-container">
                    {courseInfo.info.nonacademic != true &&
                        <div className="inspect">
                            <div className="inspect-wrapper">
                                
                                <div className="inspect-elem">
                                    <div className="inspect-elem-title" data-tip="Student's average daily course views">Views</div>
                                    <ProgressBar variant="info" now={parseFloat(student.views_avg).toFixed(2)} max={parseFloat(avgsData.views.avg).toFixed(2)} />
                                </div>
                                <div className="inspect-elem">
                                    <center><div className="inspect-elem-title" data-tip="Student's average daily course submissions">Submissions</div></center>
                                    <ProgressBar variant="info" now={parseFloat(student.submissions_avg).toFixed(2)} max={parseFloat(avgsData.submissions.avg).toFixed(2)} />

                                </div>
                                <div className="inspect-elem">
                                    <center><div className="inspect-elem-title">Attendance</div></center>
                                    <ProgressBar variant="info" label={findAttendance(classAttendance.attendance, student.login_id) + "/" + parseInt(courseInfo.info.held_classes)} now={findAttendance(classAttendance.attendance, student.login_id)} max={parseInt(courseInfo.info.held_classes)} data-tip={"Last attended: " + findLastAttended(classAttendance.attendance, student.login_id)} />

                                </div>
                            </div>
                        </div>}

                        

                        <div className="participation-bar">
                            {student.student_participation >= 0?
                            <div>
                            {100 - (student.student_participation * 100) <= 50 &&
                                <ProgressBar  variant="success" now={100 - (student.student_participation * 100)} />
                            }

                            {100 - (student.student_participation * 100) <= 75 && 100 - (student.student_participation * 100) > 50 &&
                                <ProgressBar  variant="warning" now={100 - (student.student_participation * 100)} />
                            }

                            {100 - (student.student_participation * 100) > 75 &&
                                <ProgressBar  variant="danger" now={100 - (student.student_participation * 100)} />
                            }
                            </div>:<center><FontAwesomeIcon icon={faExclamationTriangle} data-tip={"Not enough data"}></FontAwesomeIcon>  Incomplete data</center>}
                        </div>
                    </div>
                    <div className="trend-container">
                        
                        <div className="trend-arrow">
                        {(student.trend >= -.1 && student.trend <= .1) &&
                                <FontAwesomeIcon icon={faArrowRight} data-tip="Students risk was netural over the last week." />
                            }

                            {student.trend > .1 &&
                                <FontAwesomeIcon icon={faArrowDown} style={{ color: "#04d10e" }} data-tip="Students risk decreased over the last week." />
                            }

                            {student.trend < -.1 &&
                                <FontAwesomeIcon icon={faArrowUp} style={{ color: "#d10415" }} data-tip="Students risk increased over the last week." />
                            }
                        </div>
                    </div>
                </div>
            )));
            
            console.log("Getting students stop: " + new Date().getMinutes() + "|" + new Date().getSeconds());
                            
        } catch (err) {
            console.error(err.message);
        }
        
    }

    // async function deleteLogEntry(student, entry)
    // {
    //     var data={student, entry};
    //     try {
    //         var request = "/api/students/logentry/delete";
    //         const rawResponse = await fetch(request, {
    //             method: 'POST',
    //             credentials: 'include',
    //             headers: {
    //                 'Accept': 'application/json',
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify(data)
    //         });
    //         var jsonData = await rawResponse.json();
    //         if(jsonData.success)
    //             getLogEntries(student);
    //     }catch(err)
    //     {
    //         console.log(err);
    //     }


    // }

    // const editLogEntry = (e, student) =>
    // {
    //     //var editingEntryHolder = entry.entry_id;
    //     editingEntry.text = e.entry_text;
    //     editingEntry.id = e.entry_id;
    //     editingEntry.type = e.entry_type;
    //     editingEntry.contact = e.contact;
    //     editingEntry.contactDate = e.contact_date;
    //     getLogEntries(student);

    // }

    // const updateEntryText = (e) => {
    //     //setUpdatedEntryText(e.target.value);
    //     editingEntry.text = e.target.value;
    //     console.log(editingEntry.text);
    //     //getLogEntries(student);
    // }

    // const handleEntryUpdate = (e, student) =>{
    //     updateLogEntry(student);
    // }

    // const handleUpdateEntryType = (e) =>{
    //     editingEntry.type = e.target.value;
        
    // }

    // const handleUpdateContact = (e) =>{
    //     editingEntry.contact = !editingEntry.contact;
    //     e.target.checked = editingEntry.contact;
    // }

    // const handleContactDateUpdate = (e) =>{
    //     editingEntry.contactDate = e.target.value;
    // }

    // const handleTossChanges = (e) =>{
    //     handleLogClose();
    //     handleLogShow();
    // }

    // async function updateLogEntry()
    // {
    //     try {
    //         var request = "/api/students/logentry/update";
    //         const rawResponse = await fetch(request, {
    //             method: 'POST',
    //             credentials: 'include',
    //             headers: {
    //                 'Accept': 'application/json',
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify(editingEntry)
    //         });
    //         var jsonData = await rawResponse.json().then(handleLogClose()).then().then(handleLogShow());

    //         //getLogEntries(selectedStudents[0]);
    //         //handleLogShow();
            
    //     }catch(err)
    //     {
    //         console.log(err);
    //     }
    // }


    // async function getLogEntries(student) {
    //     //console.log("Waiting on entries for: " + JSON.stringify(student));
    //     //console.log(editingEntry);
    //     try {
    //         var request = "/api/students/logentry/view";
    //         const rawResponse = await fetch(request, {
    //             method: 'POST',
    //             credentials: 'include',
    //             headers: {
    //                 'Accept': 'application/json',
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify(student)
    //         });
    //     //Check for success or error
    //     var jsonData = await rawResponse.json();
    //     setLogEntries(jsonData.map(entry=>(
    //                 <div className="entry" log_text={entry.entry_text} key={entry.entry_id} type={entry.entry_type} author={entry.name} submitted={entry.submission_date} contact={entry.contact.toString()} contactdate={entry.contact_date} style={entry.entry_type === "Public"? {backgroundColor:"#c7ffcc"}:{backgroundColor:"#ffcbc7"}}>
    //                     <div className="head">
    //                     {editingEntry.id !== entry.entry_id?
    //                         <div className="type">Type: {entry.entry_type}</div>:
    //                         <div className="type">Type: 
    //                             <select defaultValue={editingEntry.type} onChange={handleUpdateEntryType}>
    //                                 <option value="Internal" >Internal</option>
    //                                 <option value="Public" >Public</option>
    //                             </select></div>}
    //                         <div className="submitted-at">Submitted: {entry.submission_date}</div>
    //                         <div className="author">Author: {entry.name}</div>
    //                     </div>
    //                     {editingEntry.id !== entry.entry_id?<div className="body">
    //                     {entry.entry_text}
    //                     </div>:
    //                     <div className="body">
    //                     <textarea defaultValue={editingEntry.text} onChange={(e) => updateEntryText(e)}></textarea>
    //                     </div>}
    //                     <div className="foot">
    //                     {editingEntry.id !== entry.entry_id?
    //                         <div className="contact" style={entry.contact? {display:'block'} :  {display:'none'}}>
    //                             Contact: {entry.contact_date}
    //                         </div>:
    //                         <div className="contact" style={{display:'inline'}}>
    //                         Contact: <input type="checkbox" defaultChecked={entry.contact?true:false} onChange={handleUpdateContact}/>
    //                         <input type="date" defaultValue={!entry.contact? getDate() : entry.contact_date.slice(0,10)} onChange={handleContactDateUpdate}/>
    //                         </div>}
    //                         {editingEntry.id === entry.entry_id?
                            
    //                         <div className="update-entry-save" data-tip="Save" ><FontAwesomeIcon className="save-button"  icon={faSave} onClick={handleEntryUpdate}/><FontAwesomeIcon className="save-button" onClick={handleTossChanges} icon={faUndoAlt}/></div>:""
    //                         }
    //                         {entry.name === getCookieValue || getCookieRole().includes('Administrator') && editingEntry.id !== entry.entry_id?
    //                         <div className="edit-entry">
    //                         <div className="delete-entry" onClick={function(){deleteLogEntry(student, entry)}}>
    //                             <FontAwesomeIcon className="delete-button" icon={faTrash}/>
    //                         </div>
    //                         <FontAwesomeIcon className="edit-button" onClick={(e) => editLogEntry(entry, student)}icon={faEdit}/>
    //                         </div>
    //                         :""}
    //                     </div>
    //                 </div>   
    //     )));
        
    //     }
    //     catch(err){
    //         console.log("Error on submit: " + err);
    //     }
    // }

    // const logSortType = () =>{
    //     var obj = [...logEntries];
    //     if(typeSort)
    //     {
    //         obj.sort(function(a, b){
    //             return a.props.type.toLowerCase().localeCompare(b.props.type.toLowerCase());
    //         });
    //     }
    //     else
    //     {
    //         obj.sort(function(a, b){
    //             return b.props.type.toLowerCase().localeCompare(a.props.type.toLowerCase());
    //         });
    //     }
    //     setTypeSort(!typeSort);
    //     setLogEntries(obj);
    //     //console.log(obj);
    // }

    // const logSortAuthor = () =>{
    //     var obj = [...logEntries];
    //     if(authSort)
    //     {
    //         obj.sort(function(a, b){
    //             return a.props.author.toLowerCase().localeCompare(b.props.author.toLowerCase());
    //         });
    //     }
    //     else
    //     {
    //         obj.sort(function(a, b){
    //             return b.props.author.toLowerCase().localeCompare(a.props.author.toLowerCase());
    //         });
    //     }
    //     setAuthSort(!authSort);
    //     setLogEntries(obj);
    //     //console.log(obj);
    // }

    // const logSortSubmission = () =>{
    //     if(submissionSort)
    //     {
    //         var obj = [...logEntries];
    //         obj.sort(function(a, b){
    //             return new Date(b.props.submitted)-new Date(a.props.submitted);
    //             //return a.props.author.toLowerCase().localeCompare(b.props.author.toLowerCase());
    //         });
    //     }
    //     else
    //     {
    //         var obj = [...logEntries];
    //         obj.sort(function(a, b){
    //             return new Date(a.props.submitted)-new Date(b.props.submitted);
    //             //return a.props.author.toLowerCase().localeCompare(b.props.author.toLowerCase());
    //         });
    //     }
    //     setSubmissionSort(!submissionSort);
    //     setLogEntries(obj);
    //     //console.log(obj);
    // }

    // const handleUploadModalShow = (e) =>{
    //     console.log("Showing upload modal");
    //     setShowLog(false);
    //     handleShowCSVModal();
    // }

    // const handleShowCSVModal = (e) =>{
    //     setShowCSVModal(true);
    // }

    const handleCSVModalClose = (e) =>{
        setShowCSVModal(false);
    }

    const handleOnDrop = (data) => {
        console.log('---------------------------')
        console.log(data)
        csvUploadCheck(data);
        setCsvFile(data);
        console.log('---------------------------')
      }
    
    const handleOnError = (err, file, inputElem, reason) => {
        console.log(err)
      }
    
    const handleOnRemoveFile = (data) => {
        console.log('---------------------------')
        console.log(data)
        console.log('---------------------------')
        setCsvErrors({});
      }

    const handleUploadFile = () =>{
        console.log('UPLOAD---------------------------')
        console.log(csvFile)
        console.log('---------------------------')
        uploadEntriesCSV(csvFile);
    }

    const getCSVErrors = (errors) =>
    {
        //console.log(errors);
        
        try{
        let filtered = errors.errors.filter(function(e){
            return (e.student || e.type || e.contact || e.contactDate)
        });
        return filtered;
    }
    catch(err)
    {
        return [];
    }
    }

    async function csvUploadCheck(data){
        try{
            
        var request = "/api/students/logentry/csvcheck";
        const rawResponse = await fetch(request, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        var jsonData = await rawResponse.json().then();
        setCsvErrors(jsonData);

        

        //getLogEntries(selectedStudents[0]);
        //handleLogShow();
        }catch(err)
        {
            console.log(err);
        }
    }

    // async function uploadEntriesCSV(data)
    // {
    //     try{
    //         var request = "/api/students/logentry/csvupload";
    //         const rawResponse = await fetch(request, {
    //             method: 'POST',
    //             credentials: 'include',
    //             headers: {
    //                 'Accept': 'application/json',
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify(data)
    //         });
    //         var jsonData = await rawResponse.json().then();
    
    //         //getLogEntries(selectedStudents[0]);
    //         //handleLogShow();
    //         }catch(err)
    //         {
    //             console.log(err);
    //         }
    // }

    // const buildCSVUploadModal = () =>{
    //     return(
    //     <Modal show={showCSVModal} onHide={handleCSVModalClose}>
    //     <Modal.Header closeButton>
    //       <Modal.Title>Student Log Upload</Modal.Title>
    //     </Modal.Header>
    //     <Modal.Body> <CSVReader
    //     onDrop={handleOnDrop}
    //     onError={handleOnError}
    //     addRemoveButton
    //     removeButtonColor='#659cef'
    //     onRemoveFile={handleOnRemoveFile}
    //     onUpload={handleUploadFile}
    //   >
    //     <span>Drop CSV file here or click to upload.</span>
    //   </CSVReader>
    //   {
    //       csvErrors.success?<div className="csv-success">Looks good!<br/>Entries: {csvErrors.errors.length}</div>:""
    //   }
    //   {!csvErrors.headers & JSON.stringify(csvErrors) !== JSON.stringify({})?
    //      <div className="header-error">Unrecognized header format. Please use the provided template.</div>:""
    //     }
    //     <div>
    //         {getCSVErrors(csvErrors).length > 0?
    //         <div className="csv-error-count">Issues identified: {getCSVErrors(csvErrors).length}</div>:""}
    //     {
            
    //         getCSVErrors(csvErrors).map(error => (
    //         <div className="csv-error-wrap"><div class="error-head">Row:{error.rowNum}</div>{error.student?<div className="csv-error">Email not recognized</div>:<div className="csv-success">StudentEmail</div>}{error.type?<div className="csv-error">Type not recognized</div>:<div className="csv-success">Type</div>}{error.entry?<div className="csv-error">Entry is blank</div>:<div className="csv-success">Entry</div>}{error.contact?<div className="csv-error">Contact(true/false) not recognized</div>:<div className="csv-success">Contact</div>}{error.contactDate?<div className="csv-error">Contact date not valid</div>:<div className="csv-success">ContactDate</div>}{error.pinned?<div className="csv-error">Pinned (true/false) not valid</div>:<div className="csv-success">Pinned</div>}</div>
    //         ))
    //     }
    //     </div>
    //     {
    //        //csvErrors.headers? <div>{JSON.stringify(csvErrors)}</div>: "" 
    //     }
    //   </Modal.Body>
    //     <Modal.Footer className="csv-modal-wrap">
    //         <div className="download-template-wrap">
    //         <CSVDownloader
    //     data={[
    //       {
    //         "StudentEmail": "testemail@canvas.com",
    //         "Type": "Public",
    //         "Entry": "Log entry text here",
    //         "Contact": "true",
    //         "ContactDate": "12-02-2021",
    //         "Pinned": "false"
    //       },
    //       {
    //         "StudentEmail": "testemail@canvas.com",
    //         "Type": "Internal",
    //         "Entry": "Log entry text here",
    //         "Contact": "false",
    //         "ContactDate": "",
    //         "Pinned": "false"
    //       }
    //     ]}
    //     type="button"
    //     filename={'dash_import_template'}
    //     bom={true}
    //   >
        
    //         <FontAwesomeIcon icon={faFileCsv}></FontAwesomeIcon>
          
    //       template
    //   </CSVDownloader>
    //       </div>
    //       <div className="upload-foot-wrap">
    //       <Button variant="secondary" onClick={handleCSVModalClose}>
    //         Close
    //       </Button>
    //       <Button variant="primary" onClick={handleUploadFile} disabled={csvErrors.success?false:true}>
    //         Upload
    //       </Button>
    //       </div>
    //     </Modal.Footer>
    //   </Modal>
    //     )
    // }

    // const buildViewModal = () => {
    //     return(
            
    //     <Modal show={showLog} onHide={handleLogClose}>
    //         <ReactTooltip />
    //     <Modal.Header closeButton>
    //         <Modal.Title>Student Log</Modal.Title>
    //         <div className="view-student-name">{selectedStudents.length>0?selectedStudents[0].studentName:""}</div>
    //     </Modal.Header>
    //     <input className="log-search-bar" type="text" onChange={editLogSearchTerm} value={logSearchTerm.value} placeholder='Search entries...' />
    //     <Modal.Body className="view-modal"><div className="entry toggle-sort">
    //     <div className="head">
    //                         <div className="type" onClick={logSortType}>Type </div>
    //                         <div className="submitted-at" onClick={logSortSubmission}>Submitted </div>
    //                         <div className="author" onClick={logSortAuthor}>Author </div>
    //                 </div>
    //                 </div>{dynamicLogSearch()}</Modal.Body>
    //     <Modal.Footer className="view-foot">
    //         <div className="csv-manage-wrapper">
    //     <div className="csv-button">
    //     <CSVDownloader
    //     data={getFormatted(logEntries)}
    //     type="button"
    //     filename={selectedStudents.length>0?selectedStudents[0].studentName +"_entries":"BLANK"}
    //     bom={true}
    //   >
    //     <FontAwesomeIcon icon={faDownload} data-tip="Download CSV"/>
    //   </CSVDownloader>
    //   </div>
    //   <div className="csv-button" data-tip="Upload CSV">
    //       <button>
    //     <FontAwesomeIcon icon={faUpload} onClick={handleUploadModalShow}/>
    //     </button>
    //   </div>
    //   </div>
    //         <Button variant="secondary" onClick={handleLogClose}>
    //             Close
    //         </Button>
            
    //     </Modal.Footer>
    // </Modal>)
    //     }
        
    // const getFormatted = (entries) =>{
        
    //     var formatted = [];
    //     for(var i = 0; i < entries.length; i++)
    //     {
    //         var entry = entries[i];
    //         console.log(entry);
    //         formatted.push({
    //             ID: entry.key,
    //             Type: entry.props.type,
    //             Entry: entry.props.log_text,
    //             Submitted: entry.props.submitted,
    //             Contact: entry.props.contact,
    //             ContactDate: entry.props.contactdate,
    //             Author: entry.props.author
    //         });
    //     }
    //     return formatted;
    // }


    const editSearchTerm = (e) => {
        setSearchTerm(e.target.value);
    }
    const dynamicSearch = () => {
        return students.filter(student => student.props.sname.toLowerCase().includes(searchTerm.toLowerCase()));
    }

    // const editLogSearchTerm = (e) => {
    //     setLogSearchTerm(e.target.value);
    // }

    // const dynamicLogSearch = (e) =>{
    //     //console.log(logEntries);
    //     return logEntries.filter(entry => entry.props.log_text.toLowerCase().includes(logSearchTerm.toLocaleLowerCase()) || entry.props.author.toLowerCase().includes(logSearchTerm.toLocaleLowerCase()));
    // }

    

    // const editEntryText = (e) => {
    //     setEntryText(e.target.value);
    // }

    // const editEntryType = (e) => {
    //     console.log("Editing entry type: " + JSON.stringify(e.currentTarget.value));
    //     setEntryType(e.currentTarget.value);
    // }

    // const setContactToggle = (e) => {
    //     setIsContact(e.target.checked);
    //     console.log(isContact);
    // }

    // const handlePinned = (e) => {
    //     setIsPinned(!isPinned);
    // }

    // const handleContactDate = (e) => {
    //     setContactDate(e.target.value);
    //     console.log("Set contact date to: " + e.target.value);
    // }

    // function getDate() {
    //     var date = new Date(); //create date object 
    //     var min_date = date.toISOString().slice(0, 10);
    //     //console.log("Returning date: " + min_date);
    //     //setContactDate(min_date);
    //     return min_date;
    // }

    // const handleEntrySubmit = () => {
        
    //     var data = {
    //         text: entryText,
    //         type: entryType,
    //         contact: isContact,
    //         contactDate: contactDate,
    //         pinned: isPinned,
    //         students: selectedStudents
    //     }

    //     console.log("Submitting Entry: ");
    //     console.log(JSON.stringify(data));
    //     if(data.text != "")
    //     {
    //         setIsSubmitting(true);
    //         submitEntry(data);
    //     }
    // }

    // async function submitEntry(data) {
        
    //     try {
    //         var request = "/api/students/logentry/add";
    //         const rawResponse = await fetch(request, {
    //             method: 'POST',
    //             credentials: 'include',
    //             headers: {
    //                 'Accept': 'application/json',
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify(data)
    //         }).then(response => response.json())
    //         .then(data => {
    //             if (data.success)
    //                 setWasSubmitted(true);
    //                 setSubmittedAtDate(data.submittedAt);
    //         })
    //     .then(setIsSubmitting(false))
    //     //Check for success or error

    //     }
    //     catch(err){
    //         console.log("Error on submit: " + err);
    //         setIsSubmitting(false);
    //         setWasSubmitted(false);
    //     }
    // }

    // const getInstructorCourses = async() => {
    //     try {
    //         const response = await fetch("http://localhost:5000/instructorcourses");
    //         const jsonData = await response.json();

    //         console.log(jsonData);
    //         setInstructorcourses(jsonData);
    //     } catch (err) {
    //         console.error(err.message);
    //     }
    // }

    useEffect(() => {
        getLastUpdate();
        getAvgsData(props);
        getCourseInfo();
        getCourseAttendance();
        getStudents(props);
        
        //getLogEntries(selectedStudents[0]);
        
        

    }, []);


    //const course_info = await getCourseInfo();

    return (
        <Fragment>
            <ReactTooltip/>
            {/* VIEW MODAL */}
            {/*buildViewModal(selectedStudents[0])*/}
            {/*buildCSVUploadModal(selectedStudents[0])*/}

            {/* CREATE MODAL */}
            {show&&
            <NewEntryModal inputRef={handleClose} props={{show: show, studentInfo:"NONE", courseID: props.props.match.params.courseID}}></NewEntryModal>
            }
            
            <div className="header-bar-instruct">
                <div className="course-title"> > {courseInfo.name}</div>
                
                <div className="log-entry-buttons">
                    <button id="log-entry" onClick={handleShow}>+ Add Log Entry</button>
                </div>
                <input className="search-bar" type="text" onChange={editSearchTerm} value={searchTerm.value} placeholder='Search by name...' />
            </div>
            <div className="course-metrics">
                    <div className="course-title">{courseInfo.name}<div data-tip={"Non-academic courses display aggregate risk scores and total number of missing assignments"} className="nonacademic-indicator">{courseInfo.nonacademic &&"*Non-Academic*"}</div></div>
                    {courseInfo.average_grade&&
                    <div className="course-average-grade">Avg Grade: {courseInfo.average_grade}%</div>}
                    {courseInfo.average_grade&&
                    <div className="course-live-class-count">Live Classes Held: {courseInfo.held_classes > 0?courseInfo.held_classes:<FontAwesomeIcon data-tip={"No live classes found"} style={{color: "orange"}} icon={faExclamationTriangle}></FontAwesomeIcon>}</div>}
                    {lastUpdate[0]?
                <div className="last-course-update"><FontAwesomeIcon icon={faInfoCircle} data-tip={"Last Update: " + lastUpdate[0].last_update.split("T")[0]}></FontAwesomeIcon></div>:""
                }
                </div>
            <div className="list-container">
                <div className="card-list">
                    <div className="student sort">
                        <div className="person">
                            <div className="profile-pic">

                            </div>
                            <div className="name" onClick={(e) => sortClick("name")}>
                                Student
                        <FontAwesomeIcon icon={faArrowDown} />
                            </div>
                        </div>
                        <div className="missing-assignments" onClick={(e) => sortClick("missing-assignments")}>
                            Missing Assignments
                        <FontAwesomeIcon icon={faArrowDown} />
                        </div>
                        <div className="participation-container" onClick={(e) => sortClick("risk")}>
                            <div className="bar-title">Risk Level<FontAwesomeIcon icon={faArrowDown} /></div>

                            <div className="participation-bar">
                            </div>
                        </div>
                        <div className="trend-container" onClick={(e) => sortClick("trend")}>
                            Trend
                        <FontAwesomeIcon icon={faArrowDown} />
                        </div>
                    </div>
                    <FlipMove>
                    {dynamicSearch()}
                    </FlipMove>
                    

                    {/* {students.map(student => (
                            <div className="student">
                                <div className="name">
                                    {student.name}
                                </div>
                                <div className="profile-pic">  
                                </div>
                                <div className="missing-assignments">
                                    Missing Assignments<br/>
                                    {student.missing_assignments}
                                </div>
                                <div className="participation-container">
                                    Participation:
                                    <div className="participation-bar">

                                    {student.student_participation*100 > 75 &&
                                    <ProgressBar striped animated variant="success" now={student.student_participation*100} />
                                    }

                                    {student.student_participation*100 < 75 && student.student_participation*100 > 60 &&
                                    <ProgressBar striped animated variant="warning" now={student.student_participation*100} />
                                    }
                                    
                                    {student.student_participation*100 &&
                                    <ProgressBar striped animated variant="danger" now={student.student_participation*100} />
                                    }
                                    
                                    </div>
                                </div>
                                <div className="trend-container">
                                    Trend
                                    <div className="trend-arrow">
                                    {student.trend === 0 &&
                                    <FontAwesomeIcon icon={faArrowRight}/>
                                    }

                                    {student.trend === 1 &&
                                    <FontAwesomeIcon icon={faArrowUp} style={{color: "#04d10e"}}/>
                                    }

                                    {student.trend === -1 &&
                                    <FontAwesomeIcon icon={faArrowDown} style={{color: "#d10415"}}/>
                                    }
                                    </div>
                                </div> 
                            </div>
                        ))} */}
                </div>
            </div>

            {/* <div className="list-container">
                    COURSES
                    <div className="card-list">
                    {courses.map(course => (
                            <div className="course">
                                <div className="course-name">
                                    {course.course_name}
                                </div>
                                <div className="student-count">
                                    Student Count<br/>
                                    <div className="student-count-text">
                                        {course.student_total}
                                    </div>
                                </div>
                                <div className="avg-grade">
                                    Average Grade<br/>
                                    <div className="student-count-text">
                                        {course.average_grade}
                                    </div>
                                </div>
                                <div className="participation-container">
                                    Participation:
                                    <div className="participation-bar">
                                        
                                    </div>
                                </div>
                                <div className="passrate-container">
                                    Pass Rate:
                                    <div className="passrate-bar">
                                    {course.pass_rate > 75 &&
                                    <ProgressBar striped animated variant="success" now={course.pass_rate} />
                                    }
                                    {course.pass_rate < 75 && course.pass_rate > 60 &&
                                    <ProgressBar striped animated variant="warning" now={course.pass_rate} />
                                    }
                                    {course.pass_rate < 60 &&
                                    <ProgressBar striped animated variant="danger" now={course.pass_rate} />
                                    }
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div> */}
        </Fragment>
    )
}

export default Students;
