import React, { Fragment, useState, useEffect } from "react";
import FlipMove from 'react-flip-move';
import { useHistory } from "react-router-dom";
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';


const Instructors = () => {

    //var server_string = "https://a0cfe9a22b3c.ngrok.io"
   
    const [instructors, setInstructors] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [instructs, setInstructs] = useState([]);
    const [instructorcourses, setInstructorcourses] = useState([]);
    const history = useHistory({forceRefresh:true});
    const [participationSort, setParticipationSort] = useState(false);
    const [ungradedSort, setUngradedSort] = useState(false);
    const [courseCountSort, setCourseCountSort] = useState(false);
    const [nameSort, setNameSort] = useState(false);
    const [messagesSort, setMessagesSort] = useState(false);
    const [updatesSort, setUpdatesSort] = useState(false);


    const courseSearch = (name) => {
        //var values = name.split(",");
        //var f_name = values[0];
        //var l_name = values[1] ? name.substr(name.indexOf(' ') + 1) : '';
        //console.log(f_name);
        //console.log(l_name);
        console.log("Name: " + name);
        history.push("/AdminHome", {params: '2', search: ''+ name});
    }

    // <option value="participation">Participation</option>
    //             <option value="instructor">Instructor</option>
    //             <option value="ungraded-count">Ungraded</option>

    const sortClick = (type) => {
        var obj = [...instructs];
        if(type==="ungraded-count")
        {
            if(ungradedSort)
            {  
                obj.sort(function(a, b){
                    return a.props.ungraded > b.props.ungraded?1:-1;
                });
            } 
            else
            {
                obj.sort(function(a, b){
                    return a.props.ungraded > b.props.ungraded?-1:1;
                });
            }
            setUngradedSort(!ungradedSort);
        }
        if(type==="course-count")
        {
            if(courseCountSort)
            {  
                obj.sort(function(a, b){
                    return a.props.courses > b.props.courses?1:-1;
                });
            } 
            else
            {
                obj.sort(function(a, b){
                    return a.props.courses > b.props.courses?-1:1;
                });
            }
            setCourseCountSort(!courseCountSort);
        }
        if(type==="name")
        {
            if(nameSort)
            {
                obj.sort(function(a, b){  
                return a.props.dataid.toLowerCase().localeCompare(b.props.dataid.toLowerCase());
                });
            } 
            else
            {
                obj.sort(function(a, b){  
                return b.props.dataid.toLowerCase().localeCompare(a.props.dataid.toLowerCase());
                });
            }
            setNameSort(!nameSort);
        }
        if(type==="messages")
        {
            if(messagesSort)
            {  
                obj.sort(function(a, b){
                    return a.props.messages - b.props.messages;
                });
            } 
            else
            {
                obj.sort(function(a, b){
                    return b.props.messages - a.props.messages;
                });
            }
            setMessagesSort(!messagesSort);
        }
        if(type==="updates")
        {
            if(updatesSort)
            {  
                obj.sort(function(a, b){
                    return a.props.updates - b.props.updates;
                });
            } 
            else
            {
                obj.sort(function(a, b){
                    return b.props.updates - a.props.updates;
                });
            }
            setUpdatesSort(!updatesSort);
        }
        setInstructs(obj);
    }

    const getInstructorUpdates = async(id) =>{
        var response_string = "";
        response_string = "/api/instructors/updatecount?id=" + id;
        try {
            const response = await fetch(response_string,{
                credentials: 'include',
                method: 'GET'
                
            });
            const jsonData = await response.json();
            return {avg: jsonData[0].avg};
        }
        catch(err)
        {
            console.error(err.message);
        }
    }

    var getObjectByValue = function (array, key, value) {
        
        var obj = array.filter(function (object) {
            if(object[key] === value)
                return object; 
        });
        try{
        //console.log("FOUND IT: " + Number(obj[0].avg).toFixed(2));
        return Number(obj[0].avg).toFixed(2);
        }
        catch(err)
        {
            return 0;
        }
    };


    const getInstructors = async(type) => {
        //console.log("Getting instructors with sort: " + type);
        var response_string = "";
        try {
            if(type)
            {
                response_string = "/api/instructors?sort_type=" + type;
                
            }
            else
                response_string = "/api/instructors";
            
            const response = await fetch(response_string,{
                credentials: 'include',
                method: 'GET'
            });
            //console.log(response);
            const jsonData = await response.json();

            
            var updates_string = "";
            updates_string = "/api/instructors/updatecount";
            const updateResponse = await fetch(updates_string,{
                credentials: 'include',
                method: 'GET'
            });
            const updateData = await updateResponse.json();

            // const count = updateData.find(user => user.instructor_id === instructor.user_id).avg
            // console.log("Updates count: " + count);
            //console.log("Update data: " + JSON.stringify(updateData));

            //console.log("Instructors: " + JSON.stringify(jsonData));
            setInstructors(jsonData);
            //console.log("Instructors: " + JSON.stringify(jsonData));
            setInstructs(jsonData.map(instructor => 
                <div className="instructor" 
                key={instructor.user_id} 
                dataid={instructor.name}
                name={instructor.name} 
                courses={instructor.course_count} 
                ungraded={instructor.ungraded_assignments}
                updates={getObjectByValue(updateData, "instructor_id", instructor.user_id)}
                messages={instructor.messages_sent}
                >
                    
                                <div className="person">
                                <div className="profile-pic">
                                        <img src={instructor.avatar_url}></img>
                                    </div>
                                    <div className="name" onClick={(e) => courseSearch(instructor.user_id)}>
                                    {instructor.name}
                                    </div>
                                    
                                </div>
                                    <div className="course-count">
                                        
                                        <div className="count-text">
                                    {instructor.course_count}
                                        </div>
                                </div>
                                <div className="ungraded-assignments">
                                    
                                    {instructor.ungraded_assignments > 100 &&
                                    <div className="ungraded-count" style={{color: "#ffc107"}}>
                                        {instructor.ungraded_assignments}
                                    </div>
                                    }
                                    {instructor.ungraded_assignments < 100 &&
                                    <div className="ungraded-count">
                                        {instructor.ungraded_assignments}
                                    </div>
                                    }
                                </div>
                                <div className="updates-per-week">
                                    
                                    <div className="updates-count">
                                        {getObjectByValue(updateData, "instructor_id", instructor.user_id)}
                                        {/* {JSON.stringify(updateData.find(block => block.instructor_id == instructor.user_id))} */}
                                    </div>
                                </div>
                                <div className="updates-per-week">
                                    
                                    <div className="updates-count">
                                        {instructor.messages_sent}
                                    </div>
                                </div>
                                {/* <div className="participation-container">
                                    Participation:
                                    <div className="participation-bar">
                        {instructor.participation > 75 &&
                        <ProgressBar striped animated variant="success" now={instructor.participation}  />
                        }
                        {instructor.participation < 75 && instructor.participation > 60 &&
                        <ProgressBar striped animated variant="warning" now={instructor.participation} />
                        }
                        {instructor.participation < 60 &&
                        <ProgressBar striped animated variant="danger" now={instructor.participation} />
                        }
                        </div>
                                </div> */}
                            </div>))
        } catch (err) {
            console.error(err.message);
        }
    }

    

    const getInstructorCourses = async(type) => {
        console.log("Getting instructors with sort: " + type);
        var response_string = "";
        try {
            if(type)
            {
                response_string = "http://localhost:5000/instructorcourses?sort_type=" + type;
                
            }
            else
                response_string = "http://localhost:5000/instructorcourses";
            const response = await fetch(response_string,{
                credentials: 'include',
                method: 'GET'
            });
            const jsonData = await response.json();
            
            //console.log(jsonData);
            setInstructorcourses(jsonData);
        } catch (err) {
            console.error(err.message);
        }
    }

    const editSearchTerm = (e) => {
        
        setSearchTerm(e.target.value);
        
    }

    const dynamicSearch = () => {
        return instructs.filter(instruct => instruct.props.dataid.toLowerCase().includes(searchTerm.toLowerCase()));
    }


    useEffect(() => {
        getInstructors();
        //getInstructorCourses();
        getInstructorUpdates();
    }, []);
    
    

    return (
            <Fragment>
                <ReactTooltip />
                <div className="header-bar-instruct">
                    <input className="search-bar" type="text" onChange={editSearchTerm} value={searchTerm.value} placeholder='Search by name...'/>
                </div>
                <div className="list-container">
                <div className="instructor sort">
                                <div className="person">
                                <div className="profile-pic">
                                    </div>
                                    <div className="name" onClick={(e) => sortClick("name")}>
                                        Instructor
                                        {nameSort?
                                        <FontAwesomeIcon icon={faArrowDown}/>
                                        :
                                        <FontAwesomeIcon icon={faArrowUp}/>}
                                    </div>
                                </div>
                                    <div className="course-count" onClick={(e) => sortClick("course-count")}>
                                        Course Count
                                        {courseCountSort?
                                        <FontAwesomeIcon icon={faArrowDown}/>
                                        :<FontAwesomeIcon icon={faArrowUp}/>
                                        }
                                </div>
                                <div className="ungraded-assignments" onClick={(e) => sortClick("ungraded-count")} data-tip="Number of ungraded assignments on the instructors ToDo list">
                                    Ungraded Submissions
                                    {ungradedSort?
                                    <FontAwesomeIcon icon={faArrowDown}/>
                                    :<FontAwesomeIcon icon={faArrowUp}/>
                                    }
                                </div>
                                <div className="updates-per-week" onClick={(e) => sortClick("updates")} data-tip="Average content updates per course last week">
                                    Course Updates
                                    {updatesSort?
                                    <FontAwesomeIcon icon={faArrowDown}/>
                                    :<FontAwesomeIcon icon={faArrowUp}/>
                                    }
                                </div>
                                <div className="updates-per-week" onClick={(e) => sortClick("messages")} data-tip="Canvas conversations last week">
                                    Canvas Conversations
                                    {messagesSort?
                                    <FontAwesomeIcon icon={faArrowDown}/>
                                    :<FontAwesomeIcon icon={faArrowUp}/>
                                    }
                                </div>
                                {/* <div className="participation-container" onClick={(e) => sortClick("participation")}>
                                    Participation
                                    <FontAwesomeIcon icon={faArrowsAltV}/>
                                </div> */}
                            </div>
                    <div className="card-list">
                        <FlipMove>
                        {dynamicSearch()}
                        </FlipMove>
                            </div>
                         
                </div>
            </Fragment>
        )
    }

    export default Instructors;